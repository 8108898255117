import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "API Connectors"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An API is the infrastructure that creates the potential for applications to share data.`}</p>
    <p>{`To actually integrate two applications, an API Connector has to be built between them.`}</p>
    <p>{`To understand this better, let’s use the airport analogy.`}</p>
    <div className="custom-description">
      <p><em parentName="p"><small>{`Imagine that you live in Sydney and want to go to Auckland. In order to do this, each city needs to have an airport. That’s the API. Both having an airport is however not enough for you to travel between them. You still need a route between them. That’s the API connector.`}</small></em></p>
      <p><em parentName="p"><small>{`If you want to go from Sydney to Auckland, you need to wait for a route to be available. Alternatively, and if you have the adequate resources, you could build your own route: fly with your own private jet!`}</small></em></p>
      <p><em parentName="p"><small>{`Indeed, once an application like enableHR has published an API, developers can use it to integrate the application with another piece of software simply by building an API Connector – a relatively straightforward job once an API is available.`}</small></em></p>
      <p><em parentName="p"><small>{`Now, if you are in Auckland and want to go to Melbourne but there is no direct route, you might be able to get there via Sydney. This network of airports (APIs) and routes (API Connectors) is called an API Ecosystem.`}</small></em></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      